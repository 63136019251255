import { SensorDto, SensorType } from 'types';
import { Vector3 } from 'three';
/* istanbul ignore file */
const generateLocation = (x: number, y: number, z: number) => ({
    id: '1',
    latitude: x,
    longitude: y,
    cota: z,
});

export const sensorIncli: SensorDto = {
    id: '4',
    ownerId: '1',
    // refId: 1,
    code: '1',
    scada: null,
    scadaId: null,
    name: 'sensor 1',
    description: 'descrição sensor 1',
    unit: '',
    model3dId: '1',
    objectId: 1,
    fragId: 1,
    externalId: '1',
    elementName: '',
    sensorType: SensorType.INCLINOMETRO,
    location: generateLocation(3.3, 6.8, 1.0),
    assetId: '1',
    limitInfAlertOne: 0.1,
    limitInfAlertTwo: null,
    limitInfAlertThree: null,
    limitInfEmergencyOne: 0.2,
    limitInfEmergencyTwo: null,
    limitInfEmergencyThree: null,
    limitSupAlertOne: null,
    limitSupAlertTwo: null,
    limitSupAlertThree: null,
    limitSupEmergencyOne: null,
    limitSupEmergencyTwo: null,
    limitSupEmergencyThree: null,
    heights: [0, 2, 3, 4],
};

export const sensores: SensorDto[] = [
    {
        id: '1',
        ownerId: '1',
        // refId: 1,
        code: '1',
        scada: null,
        scadaId: null,
        name: 'sensor 1',
        description: 'descrição sensor 1',
        heights: [],
        unit: '',
        model3dId: '1',
        objectId: 1,
        fragId: 1,
        externalId: '1',
        elementName: '',
        sensorType: SensorType.PRISMA,
        location: generateLocation(3.3, 6.8, 1.0),
        assetId: '1',
        limitInfAlertOne: 0.1,
        limitInfAlertTwo: null,
        limitInfAlertThree: null,
        limitInfEmergencyOne: 0.2,
        limitInfEmergencyTwo: null,
        limitInfEmergencyThree: null,
        limitSupAlertOne: null,
        limitSupAlertTwo: null,
        limitSupAlertThree: null,
        limitSupEmergencyOne: null,
        limitSupEmergencyTwo: null,
        limitSupEmergencyThree: null,
    },
    {
        id: '2',
        ownerId: '2',
        // refId: 2,
        code: '2',
        scada: null,
        scadaId: null,
        name: 'sensor 2',
        description: 'descrição sensor 2',
        heights: [],
        unit: '',
        model3dId: '1',
        objectId: 1,
        fragId: 1,
        externalId: '1',
        elementName: '',
        sensorType: SensorType.PRISMA,
        location: generateLocation(2.1, 3.8, 6),
        assetId: '1',
        limitInfAlertOne: 0.1,
        limitInfAlertTwo: null,
        limitInfAlertThree: null,
        limitInfEmergencyOne: 0.2,
        limitInfEmergencyTwo: null,
        limitInfEmergencyThree: null,
        limitSupAlertOne: null,
        limitSupAlertTwo: null,
        limitSupAlertThree: null,
        limitSupEmergencyOne: null,
        limitSupEmergencyTwo: null,
        limitSupEmergencyThree: null,
    },
    {
        id: '3',
        ownerId: '3',
        // refId: 3,
        code: '3',
        scada: null,
        scadaId: null,
        name: 'sensor 3',
        description: 'descrição sensor 3',
        heights: [],
        unit: '',
        model3dId: '1',
        objectId: 1,
        fragId: 1,
        externalId: '1',
        elementName: '',
        sensorType: SensorType.PRISMA,
        location: generateLocation(8.3, 2.7, 2.5),
        assetId: '1',
        limitInfAlertOne: 0.1,
        limitInfAlertTwo: null,
        limitInfAlertThree: null,
        limitInfEmergencyOne: 0.2,
        limitInfEmergencyTwo: null,
        limitInfEmergencyThree: null,
        limitSupAlertOne: null,
        limitSupAlertTwo: null,
        limitSupAlertThree: null,
        limitSupEmergencyOne: null,
        limitSupEmergencyTwo: null,
        limitSupEmergencyThree: null,
    },
    {
        id: '4',
        ownerId: '4',
        // refId: 4,
        code: '4',
        scada: null,
        scadaId: null,
        name: 'sensor 4',
        description: 'descrição sensor 4',
        heights: [],
        unit: '',
        model3dId: '1',
        objectId: 1,
        fragId: 1,
        externalId: '1',
        elementName: '',
        sensorType: SensorType.TERMOMETRO,
        location: generateLocation(8.3, 2.7, 2.5),
        assetId: '1',
        limitInfAlertOne: 0.1,
        limitInfAlertTwo: null,
        limitInfAlertThree: null,
        limitInfEmergencyOne: 0.2,
        limitInfEmergencyTwo: null,
        limitInfEmergencyThree: null,
        limitSupAlertOne: null,
        limitSupAlertTwo: null,
        limitSupAlertThree: null,
        limitSupEmergencyOne: null,
        limitSupEmergencyTwo: null,
        limitSupEmergencyThree: null,
    },
    {
        id: '5',
        ownerId: '4',
        // refId: 4,
        code: '4',
        scada: null,
        scadaId: null,
        name: 'sensor 4',
        description: 'descrição sensor 4',
        heights: [],
        unit: '',
        model3dId: '1',
        objectId: null,
        fragId: null,
        externalId: null,
        elementName: '',
        sensorType: SensorType.TERMOMETRO,
        location: generateLocation(8.3, 2.7, 2.5),
        assetId: '1',
        limitInfAlertOne: 0.1,
        limitInfAlertTwo: null,
        limitInfAlertThree: null,
        limitInfEmergencyOne: 0.2,
        limitInfEmergencyTwo: null,
        limitInfEmergencyThree: null,
        limitSupAlertOne: null,
        limitSupAlertTwo: null,
        limitSupAlertThree: null,
        limitSupEmergencyOne: null,
        limitSupEmergencyTwo: null,
        limitSupEmergencyThree: null,
    },
];
