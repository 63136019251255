import { checkCan } from 'casl/check';
import { CloudCreditDto, CloudCreditF, UserDto, UsuarioFuncao } from 'types';
import { AcessAction, AcessItem } from 'types/enum/atividades';

export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    condition?: (user: UserDto) => boolean;
    creditCondition?: (cred: CloudCreditDto[]) => boolean;
    target?: string;
    children?: MenuItemType[];
};

const MENU_ITEMS: MenuItemType[] = [
    { key: 'apps', label: 'Apps', isTitle: true },
    {
        // Ativo
        key: 'ativos',
        label: 'Ativos',
        isTitle: false,
        icon: 'uil-building',
        url: 'ativos',
    },
    {
        // Modelos 3D
        key: 'modelos-3d',
        label: 'Modelos 3D',
        isTitle: false,
        icon: 'uil-box',
        children: [
            {
                key: 'modelos',
                label: 'Modelos',
                url: 'modelos3d',
                icon: 'mdi mdi-file-cad-box',
                parentKey: 'modelos-3d',
            },
            {
                key: 'disciplinas',
                label: 'Disciplinas',
                url: 'modelos3d/disciplinas',
                icon: 'mdi mdi-clipboard-list-outline',
                parentKey: 'modelos-3d',
            },
        ],
    },
    {
        // Mapas GIS
        key: 'mapas-gis',
        label: 'Mapas GIS',
        isTitle: false,
        icon: 'mdi mdi-map-outline',
        url: 'gis',
    },
    {
        // Sensores
        key: 'sensores',
        label: 'Sensores',
        isTitle: false,
        icon: 'mdi mdi-cable-data',
        url: 'sensores',
    },
    {
        // Workflows
        key: 'workflows',
        label: 'Workflows',
        isTitle: false,
        icon: 'dripicons-to-do',
        url: 'workflow',
    },
    {
        // Atividades
        key: 'atividades',
        label: 'Atividades',
        isTitle: false,
        icon: 'dripicons-view-list',
        url: 'atividades',
    },
    {
        // Documentos
        key: 'documentos',
        label: 'Documentos',
        isTitle: false,
        icon: 'mdi mdi-file-document',
        url: 'documentos',
    },
    {
        // Monitoramento
        key: 'monitoramento',
        label: 'Monitoramento',
        isTitle: false,
        icon: 'mdi mdi-chart-areaspline',
        url: 'monitoramento',
        children: [
            {
                key: 'relatorios',
                label: 'Relatórios',
                url: 'monitoramento/relatorios',
                icon: 'mdi mdi-file-chart-outline',
                parentKey: 'monitoramento',
            },
            {
                key: 'limites',
                label: 'Alertas',
                url: 'monitoramento/limites',
                icon: 'mdi mdi-speedometer',
                parentKey: 'monitoramento',
            },
        ],
    },
    {
        // Administração - Somente deve aparecer se for administrador
        key: 'administracao',
        label: 'Administração',
        isTitle: false,
        icon: 'mdi mdi-cog-outline',
        url: 'administracao/usuarios',
        children: [
            {
                key: 'administracao-fontes',
                label: 'Fonte de Dados',
                url: 'administracao/fonte',
                icon: 'uil uil-cloud-data-connection',
                parentKey: 'administracao',
            },
            {
                key: 'administracao-users',
                label: 'Usuarios',
                url: 'administracao/usuarios',
                icon: 'mdi mdi-account-multiple',
                parentKey: 'administracao',
            },
            {
                key: 'administracao-forge',
                label: 'Modelos Forge',
                url: 'administracao/forge',
                icon: 'mdi mdi-bucket-outline',
                condition:
                    /* istanbul ignore next */
                    (user) => {
                        return checkCan(user, AcessAction.READ, AcessItem.FORGE);
                    },
                parentKey: 'administracao',
            },
            // {
            //     key: 'administracao-acc-models',
            //     label: 'Modelos ACC',
            //     url: 'administracao/modelos-acc',
            //     icon: 'mdi mdi-cloud-sync-outline',
            //     condition:
            //         /* istanbul ignore next */
            //         (user) => {
            //             return checkCan(user, AcessAction.READ, AcessItem.FORGE);
            //         },
            //     parentKey: 'administracao',
            // },
            {
                key: 'administracao-acc-account',
                label: 'Conta ACC',
                url: 'administracao/acc',
                icon: 'mdi mdi-alpha-a-box',
                condition:
                    /* istanbul ignore next */
                    (user) => {
                        return user.role === UsuarioFuncao.GLOBAL;
                    },
                parentKey: 'administracao',
            },
            {
                key: 'administracao-acc-documents',
                label: 'Documentos ACC',
                url: 'administracao/acc-docs',
                icon: 'mdi mdi-alpha-a-box',
                condition:
                    /* istanbul ignore next */
                    (user) => {
                        return user.role === UsuarioFuncao.GLOBAL;
                    },
                parentKey: 'administracao',
            },
        ],
    },
    {
        // Logout da plataforma
        key: 'logout',
        label: 'Logout',
        isTitle: false,
        icon: 'mdi mdi-logout',
        url: 'account/logout',
    },
];
export { MENU_ITEMS };
