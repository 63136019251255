import ForgeModelAssetsProperties from './features/forgeModelAssetProperties/forgeModelAssetProperties-slice';
// import SuperUsuarios from './features/superUsuario/superUsuario-slice';
// import MultiPolygon from './features/multiPolygon/multiPolygon-slice';
// import Comentarios from './features/comentarios/comentarios-slice';
// import Disciplines from './features/disciplines/disciplines-slice';
import SensoresData from './features/sensorData/sensorData-slice';
// import Atividades from './features/atividades/atividades-slice';
// import Documents from './features/documentos/documentos-slice';
// import Workflows from './features/workflows/workflows-slice';
// import Usuarios from './features/usuarios/usuarios-slice';
// import Models from './features/modelos3d/modelos3d-slice';
import DeleteModal from './features/delete/delete-slice';
// import Credits from './features/credits/credits-slice';
// import Sensores from './features/sensors/sensors-slice';
// import Actions from './features/actions/actions-slice';
// import Objects from './features/objects/objects-slice';
import Alerts from './features/alertas/alertas-slice';
import Layout from './features/layouts/layout-slice';
import Anexos from './features/anexos/anexos-slice';
import Ativos from './features/ativos/ativos-slice';
// import Marker from './features/marker/marker-slice';
// import Owners from './features/owners/owners-slice';
// import Status from './features/status/status-slice';
import Imagens from './features/image/image-slice';
import Files from './features/files/files-slice';
import Forge from './features/forge/forge-slice';
import Auth from './features/auth/auth-slice';
// import Tags from './features/tags/tags-slice';
import ACC from './features/acc/acc-slice';
import { combineReducers } from 'redux';
import { serviceApi } from './features/api/api-slice';
import { webSocketApi } from './features/api/websocket-slice';

export default combineReducers({
    [serviceApi.reducerPath]: serviceApi.reducer,
    [webSocketApi.reducerPath]: webSocketApi.reducer,
    ForgeModelAssetsProperties,
    // SuperUsuarios,
    // MultiPolygon,
    SensoresData,
    // Comentarios,
    // Disciplines,
    DeleteModal,
    // Atividades,
    // Documents,
    // Workflows,
    // Usuarios,
    // Sensores,
    // Actions,
    // Credits,
    Imagens,
    // Objects,
    // Status,
    // Models,
    // Marker,
    Alerts,
    Anexos,
    Ativos,
    Layout,
    // Owners,
    Files,
    Forge,
    Auth,
    // Tags,
    ACC,
});
