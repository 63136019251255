import { Location, SensorScadaDto } from './api';
import { positionShiftDto } from './api/positionShift';
import { AtivoF } from './ativo';
import { BaseF } from './base';
import { Modelo3dF } from './modelo3d';
import { SensorDataForm } from './sensor-data';

export enum SensorType {
    PRISMA = 'prisma',
    TRIORTOGONAL = 'triortogonal',
    PENDULO = 'pendulo',
    EXTENSOMETRO = 'extensometro',
    PIEZOMETRO = 'piezometro',
    VAZAO = 'medidor-vazao',
    TERMOMETRO = 'termometro',
    INCLINOMETRO = 'inclinometro',
}

export const SensorChartType = {
    PRISMA: 'prisma',
    TRIORTOGONAL: 'triortogonal',
    PENDULO: 'pendulo',
    EXTENSOMETRO: 'extensometro',
    PIEZOMETRO: 'piezometro',
    VAZAO: 'medidor-vazao',
    TERMOMETRO: 'termometro',
    INCLINOMETRO: 'inclinometro',
} as const;

export interface SensorF extends BaseF {
    code: string;
    name: string;
    // refId: number;
    description: string;
    ownerId: string;
    assetId: AtivoF['id'];
    model3dId: Modelo3dF['id'];
    scadaId?: string;
    scada: SensorScadaDto | null;
    objectId: number | null;
    fragId: number | null;
    externalId: string | null;
    elementName: string | null;
    heights: number[];
    unit: string;
    sensorType: SensorType;
    location: Location;
    positionShift?: positionShiftDto;
    limitInfAlertOne?: number;
    limitSupAlertOne?: number;
    limitInfEmergencyOne?: number;
    limitSupEmergencyOne?: number;
    limitInfAlertTwo?: number;
    limitSupAlertTwo?: number;
    limitInfEmergencyTwo?: number;
    limitSupEmergencyTwo?: number;
    limitInfAlertThree?: number;
    limitSupAlertThree?: number;
    limitInfEmergencyThree?: number;
    limitSupEmergencyThree?: number;
}

export interface SensorForm extends SensorF {
    data?: SensorDataForm[];
    file?: File;
}
